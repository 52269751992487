.hero {
	display: flex;
	align-items: center;
	margin-top: 50px;
	margin-bottom: 80px;
	.text-area {
		width: 50%;
		min-width: 300px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;

		p {
			max-width: 400px;
		}
		h1,
		h2 {
			font-size: 3em;
			font-weight: 800;
			max-width: 400px;
		}

		.free {
			font-size: 1.1em;
			border-radius: 4px;
			width: fit-content;
			color: #58d39a;
		}
	}

	.img-container {
		display: flex;
		width: 50%;
		justify-content: center;
		align-items: center;
		position: relative;

		img {
			height: auto;
			width: 100%;
		}

		.menu-img {
			max-width: 250px;
		}

		.image-wrapper {
			position: relative;
			.booking-img {
				max-width: 400px;
				border-radius: 3px;
				box-shadow: 1px 2px 4px #00000030;
			}
			&::before {
				content: '';
				position: absolute;
				width: 120px;
				height: calc(100% + 20px);
				background-color: #e1c4ff;
				top: -10px;
				right: -20px;
				clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
				z-index: -1;
				animation: slideIn 30s forwards alternate infinite;
			}

			@keyframes slideIn {
				from {
					right: 60%; /* Comienza desde la izquierda fuera de la vista */
				}
				to {
					right: -20px; /* Termina en la posición deseada */
				}
			}
		}
		.delivery-img-wrapper {
			margin-top: 50px;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				width: 120px;
				height: calc(100% + 20px);
				background-color: #afffd9;
				top: -10px;
				right: -20px;
				clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
				z-index: -1;
			}


			.wrapper-container {
				display: inline-block; /* para adaptarse al tamaño de la imagen */
				filter: drop-shadow(1px 2px 4px #00000030);

				img {
					clip-path: polygon(9.6% 0%, 97.9% 0%, 90.6% 100%, 0% 100%);
					display: block;
					width: 100%;
					height: auto;
				}
			}
		}
	}

	.with-border {
		box-shadow: 0px 0px 8px #0000001b;
		border-radius: 3px;
	}

	.btn-container {
		min-width: 400px;
		margin-top: 20px;
		display: flex;
		gap: 20px;
		flex-direction: column;

		.demo-btn {
			background-color: #f2f2f2;
			color: black;
			text-decoration: none;
			padding: 5px 20px;
			border-radius: 3px;
			width: 209px;
			text-align: center;
		}

		@media (max-width: 900px) {
			align-self: flex-start;
		}
	}

	@media (max-width: 768px) {
		margin-top: 40px;
		margin-bottom: 80px;
		flex-direction: column;
		text-align: center;
		gap: 50px;
		.text-area {
			width: 100%;
			h1,
			h2 {
				font-size: 2.5em;
			}
			.free {
				margin: auto;
			}
			p {
				text-wrap: pretty;
			}
		}

		.img-container {
			width: 90%;
		}

		.btn-container {
			align-self: center;
			min-width: auto;
		}
	}
}

@media (min-width: 768px) {
	.reverse {
		flex-direction: row-reverse;
	}
}
